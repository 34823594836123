import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import Footer from "components/footers/MiniCenteredFooter";
import residential_service from "images/residential_service.svg";
import rodent_control from "images/rodent_control.svg";
import zach_photo_grid from "images/zach_photo_grid.webp";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />

      <MainFeature
        subheading="Services"
        heading="Residential"
        description="Whether you're looking for a comprehensive Home Defense Plan or a One-Time Service, Z Best is committed to working with you to protect your home and family."
        imageSrc={residential_service}
        imageRounded
        imageDecoratorBlob
        buttonRounded={false}
      />
      <MainFeature
        subheading="Services"
        heading="Commercial"
        description="Our range of comprehensive Commercial Service Plans are designed to protect your business and customers from unwelcome invaders. Ask about our monthly rodent control service."
        imageSrc={rodent_control}
        imageRounded
        imageDecoratorBlob
        buttonRounded={false}
        textOnLeft={false}
      />
      <Pricing description="Our Home Defense plans are designed to provide year-round comprehensive protection of your home and property" />
      <Testimonial />
      <MainFeature
        subheading="About"
        heading="Mission & Values"
        description="Happily married with 4 beautiful children, and a proud NJ homeowner, I know the importance of maintaining a pest free environment. Pests can cause significant damage to both our health and our property.
When I began my career in pest control, I found everything about the industry so fascinating, and I couldn't wait to learn more. In less than two years I became a Certified Pesticide Applicator in 5 different categories. Fast foward 5 years later and successfully running full time routes for 2 larger companies, I realized pest control doesn't have to be that expensive. I can provide exceptional pest services at a fraction of the cost. Fully licensed and insured, I provide safe, affordable and effective treatments while following all Federal and State laws and regulations. I take pride in my services and treat every customer with dignity and respect.
- Zach Perrotta, Owner"
        buttonRounded={false}
        imageSrc={zach_photo_grid}
        textOnLeft={false}
        showFacebookFeed
        isAbout
      />
      <Footer />
    </AnimationRevealPage>
  );
};
