import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/zbestpestnj_logo_whitetext.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../../images/linkedin-icon.svg";
import { css } from "styled-components/macro"; //eslint-disable-line

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg
              src={logo}
              alt="logo"
              style={{ height: "180px", width: "180px" }}
            />
          </LogoContainer>
          <LinksContainer>
            <Link href="#home" aria-label="Go to top of page">
              Home
            </Link>
            <Link href="#services" aria-label="Read more about services">
              Services
            </Link>
            <Link href="#pricing" aria-label="Read more about pricing">
              Pricing
            </Link>
            <Link href="#about" aria-label="Read more about Zach">
              About Zach
            </Link>
            <Link
              href="https://zbestpestnj.serviceworkportal.com/forms/374/requests/new"
              aria-label="Go to servicework portal contact form"
            >
              Contact
            </Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.facebook.com/Z-Best-Pest-Control-102182422376815"
              aria-label="go to ZBestPest Facebook"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/zbestpestnj/"
              aria-label="go to ZBestPest Instagram"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/in/zachary-perrotta-484439231/"
              aria-label="go to ZBestPest LinkedIn"
            >
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2024, Z Best Pest Control. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
