import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import zbestpestnj_truck from "../../images/zbestpestnj_truck.svg";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, {
  NavLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("${zbestpestnj_truck}");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-12`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Subtext = styled.p`
  ${tw`text-xl text-center sm:text-xl lg:text-xl xl:text-2xl font-light text-gray-100 leading-snug md:w-1/2 w-full`}
`;

const PrimaryAction = tw.a`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-secondary-500 text-black hocus:bg-secondary-700 hocus:text-black focus:outline-none focus:shadow-outline`;

export default () => {
  return (
    <Container id="home">
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <Content>
          <Heading>Simply Z Best!</Heading>
          <Subtext>
            Z Best Pest Control offers expert solutions to protect your home or
            business against invading pests.
          </Subtext>
          <PrimaryAction href="tel:9739253531">
            Call Zach @ 973-925-3531
          </PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};
